<template>
  <div>
    <div class="shixun">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#fff"
        text-color="#486382"
        active-text-color="#486382"
      >
        <el-menu-item index="1">实训查询</el-menu-item>
        <el-menu-item index="2">新增实训</el-menu-item>
        <el-menu-item index="3">实训结果录入</el-menu-item>
        <el-menu-item index="4">报名统计</el-menu-item>
      </el-menu>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  name:"ShiXunLive",
  data() {
    return {};
  },
  computed: {
    activeIndex() {
      let index = this.$store.state.activeList.split("-")[1];
      return "" + index;
    },
  },
  methods: {
    handleSelect(key) {
      switch (Number(key)) {
        case 1:
          this.$router.push({ name: "SXResultCheck" });
          break;
        case 2:
          this.$router.push({ name: "ShixunAdd" });
          break;
        case 3:
          this.$router.push({ name: "SXResult" });
          break;
        case 4:
          this.$router.push({ name: "BMTJ" });
          break;
        default:
          console.log("aaa");
          break;
      }
    },
  },
};
</script>
